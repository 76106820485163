import React, { useState, useEffect } from "react"

import Test from "./test"

const StoreList = props => {
  const [lstorage, setLstorage] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }

    function success(pos) {
      var crd = pos.coords

      localStorage.setItem("latitude", crd.latitude)
      localStorage.setItem("longitude", crd.longitude)

      setLstorage(true)
      setLoading(true)
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`)
      setLstorage(false)
      setLoading(true)
    }

    navigator.geolocation.getCurrentPosition(success, error, options)
  }, [])

  return (
    <div className="grid-container">
      {loading ? (
        <Test data={props.storedata.edges} />
      ) : (
        <div className="roller-holder">
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  )
}
export default StoreList
