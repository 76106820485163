import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import StoreList from "../components/storelist"
import Nav from "../components/nav"

import { graphql } from "gatsby"
import ContactHeader from "../components/contactheader"
import Footer from "../components/footer"
import Newsletter from "../components/newsletter"

const IndexPage = props => {
  return (
    <Layout>
      <SEO
        title="Start | helpyourlocalstore"
        description="„help your local store“ soll auf Liefer- und Abholangebote in Sindelfingen und Böblingen während der Corona-Zeit aufmerksam machen und ist für die Restaurants sowie für alle Nutzer komplett kostenfrei."
      />
      <ContactHeader link="/#newsletter" />
      <Nav />
      <div className="header-img">
        <Img fadeIn={true} fluid={props.data.headerImage.childImageSharp.fluid}></Img>
      </div>
      <div className="grid-container">
        <div className="nav__intro">
          <h3>Unterstütze deine Lieblingsgeschäfte in Sindelfingen und Böblingen!</h3>
          <p>
            {" "}
            <Link className="nav__link" activeStyle={{ fontWeight: "bold", textDecoration: "underline" }} to="/">
              Listenansicht
            </Link>{" "}
            |{" "}
            <Link className="nav__link" activeStyle={{ fontWeight: "bold" }} to="/maps">
              Kartenansicht
            </Link>
          </p>
        </div>
      </div>

      <StoreList storedata={props.data.allPrismicStore} />
      <div id="newsletter">
        <Newsletter mode="full" />
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    headerImage: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allPrismicStore {
      edges {
        node {
          uid
          data {
            store_categorie
            store_coordinates {
              latitude
              longitude
            }
            store_title {
              text
            }
            store_desicription {
              text
            }
            store_logo {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 100) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            store_phonenumber
            store_openingtime {
              store_times {
                raw {
                  text
                }
              }
            }
            store_service
          }
        }
      }
    }
  }
`

export default IndexPage
