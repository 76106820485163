import React from "react"
import { Link } from "gatsby"

const ContactHeader = props => {
  return (
    <div className="contact-header">
      <p>
        Immer auf dem aktuellen Stand bleiben? Abonnieren Sie jetzt unseren Newsletter.{" "}
        <Link className="newsletter-link" to="/angebote">
          Zum Newsletter
        </Link>
      </p>
    </div>
  )
}
export default ContactHeader
