import React, { useState } from "react"
import axios from "axios"

const Newsletter = props => {
  const [mail, setMail] = useState("")
  const [isSent, setIsSent] = useState(false)
  const [sending, setSending] = useState(false)
  const [emailUsed, setEmailUsed] = useState(false)
  const formSubmit = e => {
    e.preventDefault()
    setSending(true)
    var obj = {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: mail,
      }),
    }
    fetch("https://helpyourlocalstore-newsletter.herokuapp.com/sendmail", obj)
      .then(function(result) {
        setIsSent(true)
        setEmailUsed(false)
        console.log(result)
        if (result.status === 400) {
          setEmailUsed(true)
        }
      })
      .catch(err => {
        setEmailUsed(true)
        console.log(err)
      })
  }

  const handleChange = e => {
    setMail(e.target.value)
  }

  console.log(emailUsed)

  return (
    <div>
      {props.mode === "left" ? (
        <div className="newsletter-right">
          {isSent ? (
            <div>
              {emailUsed ? (
                <p className="newsletter__success">
                  Diese E-Mail ist leider schon eingetragen. Bitte versuchen Sie es mit einer anderen E-Mail.
                </p>
              ) : (
                <p className="newsletter__success">
                  Vielen Dank für dein Interesse! Wir haben dir einen Bestätigungslink an deine E-Mail Adresse
                  geschickt.
                </p>
              )}
            </div>
          ) : (
            <form onSubmit={e => formSubmit(e)}>
              <input
                onChange={handleChange}
                value={mail}
                type="email"
                placeholder="E-Mail eingeben.."
                name="email"
                className="newsletter-right__input"
                required
              />
              {sending ? (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <button className="newsletter-right__btn" type="submit" value="submit">
                  Einschreiben
                </button>
              )}
            </form>
          )}
        </div>
      ) : (
        <div className="grid-container">
          <div className="newsletter">
            <h3 className="newsletter__headline">Abonniere jetzt unseren beliebten Newsletter!</h3>
            <div className="newsletter__text">
              <p className="newsletter__checklist">
                Keine Neuigkeiten rund um deine lokalen Lieblingsgeschäfte mehr verpassen
              </p>
              <p className="newsletter__checklist">Exklusive Angebote und Vorteile sichern</p>
              <p className="newsletter__checklist">Keine versteckten Gebühren, alles komplett kostenlos</p>
            </div>
            {isSent ? (
              <div>
                {emailUsed ? (
                  <p className="newsletter__success">
                    Diese E-Mail ist leider schon eingetragen. Bitte versuchen Sie es mit einer anderen E-Mail.
                  </p>
                ) : (
                  <p className="newsletter__success">
                    Vielen Dank für dein Interesse! Wir haben dir einen Bestätigungslink an deine E-Mail Adresse
                    geschickt.
                  </p>
                )}
              </div>
            ) : (
              <form onSubmit={e => formSubmit(e)}>
                <input
                  onChange={handleChange}
                  value={mail}
                  type="email"
                  placeholder="E-Mail eingeben.."
                  name="email"
                  className="newsletter__input"
                  required
                />
                {sending ? (
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <button className="newsletter__btn" type="submit" value="submit">
                    Jetzt einschreiben!
                  </button>
                )}
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default Newsletter
